import React from "react";

const Features = () => {
  return (
    <div>
      <h1>Welcome to Munitora</h1>
      <p>App Features</p>
      {/* Add more content as needed */}
    </div>
  );
};

export default Features;
