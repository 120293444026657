import React, { useContext } from "react";
import "../../assets/styles/Home.scss";
import Navbar from '../common/NavBar.js';
import TitleUnderline from "../../assets/images/HomeUnderline.png";
import Farmers from "../../assets/images/MissionFarmers.png";
import CowRocket from "../../assets/images/CowRocket.png";
import BackgroundWave from "../../assets/images/GreenWaveBackground.png";  
import { LanguageContext } from '../common/LanguageContext.js';

const translations = {
  EN: {
    bolded:"Our",
    title: "mission",
    subtitle: "We empower farmers with data and help them remove waste in their operations."
  },
  PT: {
    bolded: "Nossa",
    title: "missão",
    subtitle: "Capacitamos pecuaristas com dados e os ajudamos a remover desperdícios em suas operações."
  }
};

const Home = () => {
  const { language } = useContext(LanguageContext);
  const { title, bolded, subtitle } = translations[language];

  return (
    <>
      <Navbar />
      <div className="home-main">
        <div className="content">
          <div className="mission-content">
            <h1 className="title"><span className="title-bolded">{bolded}</span> {title}</h1>
            <img className="home-underline" src={TitleUnderline} alt="Underline" />
            <h2 className="subtitle">{subtitle}</h2>
            <img className="farmers" src={Farmers}/>
            <img className="background-wave" src={BackgroundWave}/>
            <img className="cow-rocket" src={CowRocket}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
