import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "../components/pages/Home";
import AboutUs from "../components/pages/AboutUs";
import LearnMore from "../components/pages/LearnMore";
import Features from "../components/pages/Features";
import HowItWorks from "../components/pages/HowItWorks";
import NotFoundPage from "../components/pages/NotFoundPage";
import "../assets/styles/styles.scss";

const AppContent = () => {
  const location = useLocation();
  const isNonScrollable = location.pathname !== "/about-us";

  return (
    <div className={isNonScrollable ? "non-scrollable" : ""}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/learn-more" element={<LearnMore />} />
        <Route path="/features" element={<Features />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

const AppRoutes = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default AppRoutes;
