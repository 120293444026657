import React, { useState, useContext, useEffect } from "react";
import "../../assets/styles/LearnMore.scss";
import Navbar from "../common/NavBar.js";
import TitleUnderline from "../../assets/images/LearnUnderline.png";
import { LanguageContext } from "../common/LanguageContext.js";

const translations = {
  EN: {
    title: "Learn",
    bolded: "more",
    subtitle:
      "Stay informed about our latest updates, announcements and insights by joining mailing list! Leave us a message and we will stay connected.",
    fullname: "Full name*",
    who: "Who are you?",
    farmer: "Farmer",
    investor: "Investor",
    other: "Other",
    email: "Email*",
    message: "Message",
    submit: "Submit",
  },
  PT: {
    title: "Saiba",
    bolded: "mais",
    subtitle:
      "Mantenha-se informado sobre as nossas últimas atualizações, anúncios e insights juntando-se à lista de discussão! Deixe-nos uma mensagem.",
    fullname: "Nome completo*",
    who: "Quem é você?",
    farmer: "Fazendeiro",
    investor: "Investidor",
    other: "Outro",
    email: "Email*",
    message: "Mensagem",
    submit: "Enviar",
  },
};

const LearnMore = () => {
  const { language } = useContext(LanguageContext);
  const {
    title,
    bolded,
    subtitle,
    fullname,
    who,
    farmer,
    investor,
    other,
    email,
    message,
    submit,
  } = translations[language];

  const [formData, setFormData] = useState({
    fullName: "",
    category: "",
    email: "",
    message: "",
  });

  const [animationClass, setAnimationClass] = useState("fade-in");

  useEffect(() => {
    setAnimationClass("fade-in");
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://jacuvlm4m3.execute-api.us-west-2.amazonaws.com/prod/send-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        alert("Email sent successfully");
      } else {
        alert("Failed to send email");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <Navbar />
      <div className={`learn-main ${animationClass}`}>
        <h1 className="title">
          {title} <span className="title-bolded">{bolded}</span>
        </h1>
        <img className="learn-underline" src={TitleUnderline} alt="Underline" />
        <h2 className="subtitle">{subtitle}</h2>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="form-group">
              <input
                className="name-input"
                type="text"
                id="fullName"
                name="fullName"
                placeholder={fullname}
                required
                value={formData.fullName}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <select
                id="category"
                name="category"
                required
                value={formData.category}
                onChange={handleChange}
              >
                <option value="">{who}</option>
                <option value="farmer">{farmer}</option>
                <option value="investor">{investor}</option>
                <option value="other">{other}</option>
              </select>
            </div>
          </div>
          <div className="form-group">
            <input
              className="email-input"
              type="email"
              id="email"
              name="email"
              placeholder={email}
              required
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <textarea
              className="message-input"
              id="message"
              name="message"
              placeholder={message}
              value={formData.message}
              onChange={handleChange}
            />
          </div>
          <button className="submit-button" type="submit">
            {submit}
          </button>
        </form>
      </div>
    </>
  );
};

export default LearnMore;
