import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useClickAway } from 'react-use';
import "../../assets/styles/NavBar.scss";
import NavBarLogo from "../../assets/images/NavBarLogo.png";
import Brazil from "../../assets/images/BrazilFlag.png";
import Canada from "../../assets/images/CanadaFlag.png";
import DropdownArrow from "../../assets/images/DropdownArrow.png";
import HamburgerIcon from "../../assets/images/HamburgerIcon.png";
import { LanguageContext } from './LanguageContext';

const translations = {
  EN: {
    about:"About",
    how: "How It Works",
    learn: "Learn More"
  },
  PT: {
    about:"Sobre",
    how: "Como Funciona",
    learn: "Saiba Mais"
  }
};

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const { language, setLanguage } = useContext(LanguageContext);
  const { about, how, learn } = translations[language];
  const selectedLanguage = language === 'EN' ? { code: 'EN', img: Canada } : { code: 'PT', img: Brazil };
  const menuRefMobile = useRef();
  const menuRefDesktop = useRef();

  useClickAway(menuRefMobile, () => setMenuOpen(false));

  useEffect(() => {
    let handler = (e) => {
      if (!menuRefDesktop.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleLanguageChange = (language, img) => {
    setLanguage(language);
    setOpen(false);
  };

  return (
    <nav className="navbar">
      <Link to="/">
        <img className="logo" src={NavBarLogo} alt="Munitora Logo" />
      </Link>
      <div className="hamburger-menu" onClick={() => setMenuOpen(!menuOpen)}>
        <img className="hamburger-icon" src={HamburgerIcon} alt="Menu"/>
      </div>
      <AnimatePresence>
        {menuOpen && (
          <motion.ul
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="navbar-links-mobile"
          >
            <li>
              <div className="language-dropdown" ref={menuRefMobile}>
                <div className="dropdown-trigger" onClick={() => setOpen(!open)}>
                  <button className="language-button">
                    <img src={selectedLanguage.img} alt={selectedLanguage.code} className="language-icon" />
                    {selectedLanguage.code}
                    <img src={DropdownArrow} className="arrow" />
                  </button>
                </div>
                <div className={`hamburger-dropdown-menu ${open ? 'active' : 'inactive'}`}>
                  <ul className="menu-space">
                    <DropdownItem img={Brazil} text={"PT"} onClick={() => handleLanguageChange('PT', Brazil)} />
                    <DropdownItem img={Canada} text={"EN"} onClick={() => handleLanguageChange('EN', Canada)} />
                  </ul>
                </div>
              </div>
            </li>
            <li><button className="about-us-button" onClick={() => setMenuOpen(false)}><Link to="/about-us">{about}</Link></button></li>
            <li><button className="how-button" onClick={() => setMenuOpen(false)}><Link to="/how-it-works">{how}</Link></button></li>
            <li><button className="learn-more-button" onClick={() => setMenuOpen(false)}><Link to="/learn-more">{learn}</Link></button></li>
          </motion.ul>
        )}
      </AnimatePresence>
      <ul className="navbar-links">
        <li>
          <div className="language-dropdown" ref={menuRefDesktop}>
            <div className="dropdown-trigger" onClick={() => setOpen(!open)}>
              <button className="language-button">
                <img src={selectedLanguage.img} alt={selectedLanguage.code} className="language-icon" />
                {selectedLanguage.code}
                <img src={DropdownArrow} className="arrow" />
              </button>
            </div>
            <div className={`dropdown-menu ${open ? 'active' : 'inactive'}`}>
              <ul className="menu-space">
                <DropdownItem img={Brazil} text={"PT"} onClick={() => handleLanguageChange('PT', Brazil)} />
                <DropdownItem img={Canada} text={"EN"} onClick={() => handleLanguageChange('EN', Canada)} />
              </ul>
            </div>
          </div>
        </li>
        <li><button className="about-us-button"><Link to="/about-us">{about}</Link></button></li>
        <li><button className="how-button"><Link to="/how-it-works">{how}</Link></button></li>
        <li><button className="learn-more-button"><Link to="/learn-more">{learn}</Link></button></li>
      </ul>
    </nav>
  );
}

function DropdownItem({ img, text, onClick }) {
  return (
    <li className="dropdown-item" onClick={onClick}>
      <img src={img} alt={text} />
      <a>{text}</a>
    </li>
  );
}

export default Navbar;
