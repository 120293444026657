import React, { useContext } from "react";
import "../../assets/styles/AboutUs.scss";
import Navbar from '../common/NavBar.js';  
import TitleUnderline from "../../assets/images/AboutUnderline.png";
import TeamPic from "../../assets/images/Team.png";
import { LanguageContext } from '../common/LanguageContext.js';

const translations = {
  EN: {
    title:"About",
    bolded: "us",
    team_description: `We are a passionate team of four Brazilian engineering students united by a common vision to innovate within the agricultural sector. Our journey began in November 2023, when we merged our diverse experience in software development, process improvement, and cattle farming to address critical challenges in livestock management.<br><br>Working closely with farmers, we've meticulously tailored our solutions to meet the real-world needs of modern agriculture. Our mission is to transform livestock management by providing farmers with advanced tools and actionable insights that drive efficiency, improve yields, and increase profitability. <br><br>At Munitora, we are dedicated to empowering farmers with the data they need to thrive in this crucial industry.`
  },
  PT: {
    title: "Sobre",
    bolded: "nós",
    team_description: `Somos uma equipe de estudantes de engenharia brasileiros com uma visão comum de inovar dentro do setor agrícola. Nossa jornada começou em novembro de 2023, quando unimos nossa experiência diversificada em desenvolvimento de software, melhoria de processos e pecuária para enfrentar desafios críticos na gestão pecuária.<br><br> Trabalhando em estreita colaboração com pecuaristas brasileiros, adaptamos meticulosamente nossas soluções para atender às necessidades reais da agropecuária moderna. Nossa missão é transformar a gestão do gado, fornecendo aos pecuaristas ferramentas avançadas e insights que impulsionam a eficiência, melhoram os rendimentos e aumentam a lucratividade.<br><br> No Munitora, nos dedicamos a capacitar criadores de gado com os dados de que precisam para prosperar nesta importante indústria.`
  }
};

const AboutUs = () => {
  const { language } = useContext(LanguageContext);
  const { title, bolded, team_description } = translations[language];
  
  return (
    <>
      <Navbar/>
      <div className="about-main">
        <h1 className="title">{title} <span className="title-bolded">{bolded}</span></h1>
        <img className="about-underline" src={TitleUnderline} alt="Underline"/>
        <div className="row">
          <img className="team-pic" src={TeamPic} alt="Picture of Team"/>
          <h2 className="team-desc" dangerouslySetInnerHTML={{ __html: team_description }}></h2> 
        </div>
      </div>
    </>
  );
};

export default AboutUs;
