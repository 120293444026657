import React, { useState, useContext } from "react";
import "../../assets/styles/HowItWorks.scss";
import Navbar from "../common/NavBar.js";
import TitleUnderline from "../../assets/images/HomeUnderline.png";
import LeftArrow from "../../assets/images/LeftArrow.png";
import RightArrow from "../../assets/images/RightArrow.png";
import Cow from "../../assets/images/HowCow.png";
import BackgroundWave from "../../assets/images/GreenWaveBackground.png"; 
import Reader from "../../assets/images/HowReader.png";
import ReaderMobile from "../../assets/images/HowReaderMobile.png";
import App from "../../assets/images/HowApp.png";
import CowProfile from "../../assets/images/CowProfile.png";
import Productivity from "../../assets/images/Productivity.png";
import Employees from "../../assets/images/Employees.png";
import { LanguageContext } from "../common/LanguageContext.js";

const translations = {
  EN: {
    title: "How",
    bolded: "it",
    title2: "works",
    subtitle:
      "Each animal is equipped with an RFID tag. The farmer uses our reader to interface the animal with the Munitora App.",
    animal: "Management of herd and animals",
    animal_desc:
      "Manage animals individually, track their weight progress, vaccination records, organize your herd and much more.",
    powerful: "Powerful insights and KPI tracking",
    powerful_desc:
      "Obtain A.I. powered insights and reports, track your farm’s most important KPI’s and make data-driven decisions.",
    people: "People and resource management",
    people_desc:
      "Manage your employees with our task assigning feature. Manage your inventory with our inventory management tool.",
  },
  PT: {
    title: "Como",
    bolded: "funciona",
    title2: "",
    subtitle:
      "Cada animal é equipado com um chip ou brinco RFID. O pecuarista usa nosso leitor para fazer a interface do animal com o aplicativo Munitora.",
    animal: "Manejo de animais e rebanhos",
    animal_desc:
      "Gerencie animais individualmente, acompanhe o progresso de peso, registros de vacinação, organize seu rebanho e muito mais.",
    powerful: "Insights poderosos e métricas",
    powerful_desc:
      "Obtenha insights e relatórios baseados em IA, acompanhe as métricas mais importantes de sua fazenda e tome decisões baseadas em dados.",
    people: "Gestão de pessoas e recursos",
    people_desc:
      "Gerencie seus funcionários com nosso recurso de atribuição de tarefas. Gerencie seu estoque com nossa ferramenta de gestão de estoque.",
  },
};

const HowItWorks = () => {
  const { language } = useContext(LanguageContext);
  const {
    title,
    bolded,
    title2,
    subtitle,
    animal,
    animal_desc,
    powerful,
    powerful_desc,
    people,
    people_desc,
  } = translations[language];
  const [currentCard, setCurrentCard] = useState(0);
  const [animationClass, setAnimationClass] = useState("");

  const totalCards = 4;

  const prevCard = () => {
    setAnimationClass("fade-out");
    setTimeout(() => {
      setCurrentCard((prev) => (prev === 0 ? totalCards - 1 : prev - 1));
      setAnimationClass("fade-in");
    }, 1000);
  };

  const nextCard = () => {
    setAnimationClass("fade-out");
    setTimeout(() => {
      setCurrentCard((prev) => (prev === totalCards - 1 ? 0 : prev + 1));
      setAnimationClass("fade-in");
    }, 1000);
  };

  return (
    <>
      <Navbar />
      <div className="how-main">
        <div className={`content ${animationClass}`}>
          {currentCard === 0 && (
            <div className="content-card">
              <h1 className="title">
                {title} <span className="title-bolded">{bolded} </span>
                {title2}
              </h1>
              <img
                className="how-underline"
                src={TitleUnderline}
                alt="Underline"
              />
              <h2 className="subtitle">{subtitle}</h2>
              <img className="cow" src={Cow} alt="Cow" />
              <img className="reader-mobile" src={ReaderMobile} alt="Reader"/>
              <img className="background-wave" src={BackgroundWave}/>
              <img className="reader" src={Reader} alt="Reader" />
              <img className="app" src={App} alt="App" />
            </div>
          )}
          {currentCard === 1 && (
            <div className="content-card">
              <h1 className="second-title">{animal}</h1>
              <img className="background-wave" src={BackgroundWave}/>
              <h2 className="second-subtitle">{animal_desc}</h2>
              <img className="mockup" src={CowProfile} alt="Cow Profile" />
            </div>
          )}
          {currentCard === 2 && (
            <div className="content-card">
              <h1 className="second-title">{powerful}</h1>
              <img className="background-wave" src={BackgroundWave}/>
              <h2 className="second-subtitle">{powerful_desc}</h2>
              <img className="mockup" src={Productivity} alt="Productivity" />
            </div>
          )}
          {currentCard === 3 && (
            <div className="content-card">
              <h1 className="second-title">{people}</h1>
              <img className="background-wave" src={BackgroundWave}/>
              <h2 className="second-subtitle">{people_desc}</h2>
              <img className="mockup" src={Employees} alt="Employees" />
            </div>
          )}
          <button onClick={prevCard} className="arrow-left">
            <img className="left-icon" src={LeftArrow} alt="Left Arrow" />
          </button>
          <button onClick={nextCard} className="arrow-right">
            <img className="right-icon" src={RightArrow} alt="Right Arrow" />
          </button>
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
